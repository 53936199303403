@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --primary-color: #9e67c0;
  --dark-blue-color: #9e67c0;
  --grey-color: #505050;
  --light-grey-color: #e2e1e1;
  --f-white-color: #fdfdfd;
  unicode-bidi: plaintext;
  text-align: start;
  /* --dark-grey-color: ; */
}

html {
  --border-radius: 0.8rem;
  height: 100%;

  /* font-size: 62.5%; */
}

a {
  text-decoration: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #9579a926;
  color: var(--grey-color);
  font-family: 'Poppins', sans-serif;
  height: 100%;
}

body[dir='ltr'] {
  text-align: left;
}
body[dir='rtl'] {
  text-align: right;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background-color: #184b5715;
  -webkit-border-radius: 0.8rem;
  border-radius: 0.8rem;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0.8rem;
  border-radius: 0.8rem;
  background: #e2e1e1;
}

.aligned {
  position: fixed;
  top: 50%;
  left: 50%;
  text-align: center;

  transform: translate(-50%, -50%);
}

.image-toolbar {
  position: fixed;
  bottom: 7px;
  left: 50%;
  transform: translate(-50%, -0%);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2px;
  border-radius: 2px;
  z-index: 9999999999999999999;
}

.MuiFormControlLabel-root .Mui-disabled div {
  color: black;
}

/*fix image overflowing bug in rich text editor*/
span[class^='ImageSpan_'] {
  letter-spacing: 0 !important;
  background-size: contain !important;
  width: 100% !important;
  height: auto !important;
  font-size: medium !important;
  line-height: 200px !important;
}
